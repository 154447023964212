import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import instance, { fetchChurch } from '../axiosInstance'; // Asegúrate de que esta instancia esté configurada correctamente
import { useTranslation } from 'react-i18next';
import { getLocaleFilter, LOCALE_ES } from '../i18n';
import './ChurchDetails.css'
import { Layout } from 'antd';
import SEOHeader from '../components/SEOHeader';

export const BASE_URL = process.env.REACT_APP_MEDIA_URL;

const ChurchDetail = () => {
  const { churchId } = useParams(); // Obtiene el uid de la iglesia desde la URL
  const [churchData, setChurchData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { i18n, t } = useTranslation();
  const currentLang = i18n.language ? i18n.language : LOCALE_ES;

  useEffect(() => {
    const fetchChurchData = async () => {
      try {
        let fetchedData = await fetchChurch(currentLang, churchId);
  
        if (!fetchedData && currentLang === 'es') {
          fetchedData = await fetchChurch('zh', churchId);
        }
        if (fetchedData) {
          setChurchData(fetchedData);
        } else {
          setError('No se encontró información de la iglesia.');
        }
      } catch (err) {
        setError('Error al cargar la información de la iglesia.');
      } finally {
        setLoading(false);
      }
    };
    fetchChurchData();
  }, [churchId]);

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;

  // Extraer atributos de la respuesta
  const { name, churchPic, information, contactInfo, informationPic, pastor } = churchData.attributes;

  // Formatear la información para mostrarla
  const informationText = information.map((info) => info.children.map((child) => child.text).join('')).join('\n');

  // Traducción de días de la semana
  const translatedDays = {
    monday: t('monday'),
    tuesday: t('tuesday'),
    wednesday: t('wednesday'),
    thursday: t('thursday'),
    friday: t('friday'),
    saturday: t('saturday'),
    sunday: t('sunday'),
  };

  // Formatear los horarios de reuniones
  const formatTime = (time) => {
    return time.slice(0, 5); // Esto corta los segundos, mostrando solo HH:mm
  };

  const meetingTime = contactInfo.meetingTime
    ? `${contactInfo.meetingTime.weekDay
      .map((day) => translatedDays[day.toLowerCase()])
      .join(', ')} ${formatTime(contactInfo.meetingTime.start)} - ${formatTime(contactInfo.meetingTime.end)}`
    : null;


  return (
    <Layout className="church-detail">
      <SEOHeader titleKey='icce_title' descriptionKey='icce_title' />
      <h1><span className='icce-title'>{t('icce_title')}</span> <span className='divider'>|</span> {name}</h1>
      <div className="church-section church-section-two">
        {churchPic && (
          <img
            src={`${BASE_URL}${churchPic.data.attributes.url}`}
            alt={name}
          />
        )}

        <div className="church-contact-info">
          {contactInfo.location && <p>{t('location')}: {contactInfo.location}</p>}
          {contactInfo.contactPerson && <p>{t('contact_person')}: {contactInfo.contactPerson}</p>}
          {contactInfo.phoneNumber && <p>{t('contact_phone')}: {contactInfo.phoneNumber}</p>}
          {pastor && <p>{t('church_pastor')}: {pastor || t('not_available')}</p>}
          {meetingTime && <p>{t('meeting_time')}: {meetingTime}</p>}
        </div>
      </div>
      <div className="church-section church-section-three">
        {informationPic ? (
          <>
            <img
              src={`${BASE_URL}${informationPic.data.attributes.url}`}
              alt="Información adicional"
            />
            <div className="church-information">
              <p>{informationText}</p>
            </div>
          </>
        ) : (
          <div className="church-information full-width">
            <p>{informationText}</p>
          </div>
        )}
      </div>

    </Layout>
  );

};

export default ChurchDetail;
