// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import { ConfigProvider } from 'antd';
import History from './pages/History'; 
import HistoryTimeline from './pages/HistoryTimeline'; 
import OurPastors from './pages/OurPastors'; 
import OrganizationalStructure from './pages/OrganizationalStructure'; 
import ContactUs from './pages/ContactUs'; 
import Navbar from './components/Navbar';
import './App.css';
import CustomFooter from './components/CustomFooter';
import ChurchDetails from './pages/ChurchDetails';
import FaithDeclaration from './pages/FaithDeclaration';
import ChurchRequestDetails from './pages/ChurchRequestDetails';
const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#d46b08",
          colorBgBase: "#fff7e6",
          colorText: '#873800',
          fontFamily: `"Trebuchet MS", Tahoma, sans-serif`
        },
      }}
    >
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/history" element={<History />} />
          <Route path="/faith-declaration" element={<FaithDeclaration />} />
          <Route path="/history-timeline" element={<HistoryTimeline />} />
          <Route path="/our-pastors" element={<OurPastors />} />
          <Route path="/organizational-structure" element={<OrganizationalStructure />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/church/:churchId" element={<ChurchDetails />} /> {/* Ruta dinámica */}
          <Route path="/church-request/:requestId" element={<ChurchRequestDetails />} /> {/* Ruta dinámica */}
        </Routes>
        <CustomFooter></CustomFooter>
      </Router>
    </ConfigProvider>
  );
};

export default App;
