import React, { useEffect } from 'react';
import { Layout, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import SEOHeader from '../components/SEOHeader';
import BackgroundImage from '../components/BackgroundImage';
import apostlesCreedEs from '../content/apostles_creed_es.json';
import apostlesCreedZh from '../content/apostles_creed_zh.json';
import niceneCreedEs from '../content/nicene_creed_es.json';
import niceneCreedZh from '../content/nicene_creed_zh.json';
import './FaithDeclaration.css';
import { useLocation } from 'react-router-dom';

const FaithDeclaration = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { t, i18n } = useTranslation();
  const currentLang = i18n.language || 'es'; 
  const apostlesCreedContent = currentLang === 'es' ? apostlesCreedEs.sections : apostlesCreedZh.sections;
  const niceneCreedContent = currentLang === 'es' ? niceneCreedEs.sections : niceneCreedZh.sections;

  const creedsArray = [
    {
      title: t('apostles_creed'),
      content: apostlesCreedContent
    },
    {
      title: t('nicene_creed'),
      content: niceneCreedContent
    }
  ];

  return (
    <div>
      <SEOHeader titleKey='faith' descriptionKey='faith' />
      <Layout className="faith-layout">
        <BackgroundImage title='faith_declaration' bgImgClass="faith-bg-image" />

        {creedsArray.map((creed, index) => (
          <div key={index} className="faith-card-container">
            <Card className="faith-card">
              <h1>《{creed.title}》</h1>
              <div className="faith-section">
                {creed.content.map((paragraph, idx) => (
                  <p key={idx}>{paragraph.text}</p>
                ))}
              </div>
            </Card>
          </div>
        ))}
      </Layout>
    </div>
  );
};

export default FaithDeclaration;
