import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import instance, { fetchChurchRequestDetails } from '../axiosInstance'; // Asegúrate de que esta instancia esté configurada correctamente
import { useTranslation } from 'react-i18next';
import { LOCALE_ES } from '../i18n';
import './ChurchRequestDetails.css'
import { Layout } from 'antd';
import SEOHeader from '../components/SEOHeader';
import RichTextRenderer from '../components/RichTextRenderer';

export const BASE_URL = process.env.REACT_APP_MEDIA_URL;

const ChurchRequestDetails = () => {
  const { requestId } = useParams(); // Obtiene el uid de la iglesia desde la URL
  const [churchRequestData, setChurchRequestData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { i18n, t } = useTranslation(); // Añadimos la función de traducción
  const currentLang = i18n.language ? i18n.language : LOCALE_ES;

  useEffect(() => {
    const fetchChurchData = async () => {
      try {
        const fetchedData = await fetchChurchRequestDetails(currentLang, requestId); // Usar uid para filtrar
        if (!fetchedData && currentLang === LOCALE_ES) {
          fetchedData = await fetchChurchRequestDetails('zh');
        }
        if (fetchedData) {
          setChurchRequestData(fetchedData); // Guarda los datos de la iglesia
        } else {
          setError('Error al cargar la información de la iglesia.');
        }
      } catch (err) {
        setError('Error al cargar la información de la iglesia.');
      } finally {
        setLoading(false);
      }
    };

    fetchChurchData();
  }, [requestId]);

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;

  const { content, title } = churchRequestData;

  return (
    <Layout className="church-request-detail">
      <SEOHeader titleKey='icce_title' descriptionKey='icce_title' />
      <h1>{title}</h1>
      <div className="church-request-section">
        <RichTextRenderer content={content} />
      </div>
    </Layout>
  );

};

export default ChurchRequestDetails;
