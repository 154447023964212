// src/components/ChurchList.js
import React from 'react';
import { Link } from 'react-router-dom';
import churches from '../content/churches.json';
import './ChurchList.css'; // Estilo opcional para el componente
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';

const ChurchList = () => {
    const { t } = useTranslation();
    return (
        <Layout className="church-list">
            <h2>{t('churches')}</h2>
            <div className="church-list-container">
                {churches.map((church) => (
                    church.url?
                    <a className="church-list-item" href={church.url} target="_blank">{t(church.key)} </a>:
                    <Link
                        key={church.id}
                        className="church-list-item"
                        to={`/church/${church.id}`}
                    >
                        {t(church.key)}
                    </Link>
                ))}
            </div>
        </Layout>
    );
};

export default ChurchList;
