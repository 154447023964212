import React from 'react';
import { Layout, Row, Col } from 'antd';
import { MailOutlined, EnvironmentOutlined, RightOutlined } from '@ant-design/icons'; // Añadir RightOutlined
import './CustomFooter.css'; // Archivo CSS para estilos del footer
import { useTranslation } from 'react-i18next';
import { TfiYoutube } from "react-icons/tfi";

const { Footer } = Layout;

const CustomFooter = () => {
  const { t } = useTranslation();

  return (
    <Footer className="custom-footer">
      <div className="footer-container">
        <Row justify="space-around" align="top" gutter={[32, 32]}>
          {/* Columna para Logo y Nombre */}
          <Col xs={24} sm={12} md={8} lg={6} className="footer-logo" style={{ textAlign: 'center' }}>
            <img src="/logo-trans.png" alt="ICCE Logo" className="logo" />
            <h2 className="icce-name">{t('icce_title')}</h2>
          </Col>

          {/* Columna para Información de Contacto */}
          <Col xs={24} sm={12} md={8} lg={7} className="footer-contact">
            <h3>{t('contact')}</h3>

            <p>
              <MailOutlined />{' '}
              <a href="mailto:Info@iccespain.com">Info@iccespain.com</a>
            </p>
            <p>
              <MailOutlined />{' '}
              <a href="mailto:Oficina@iccespain.com">Oficina@iccespain.com</a>
            </p>
            <p className='location-line'>
              <EnvironmentOutlined />{' '}
              <a
                href="https://maps.app.goo.gl/Rjzqg7xjdW4fzdGE7"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('office_address')}
              </a>
            </p>
            <a className='youtube' href="https://www.youtube.com/@iccecomes" target="_blank">
              <TfiYoutube />{' '}
            </a>
          </Col>

          {/* Columna para Enlaces a Secciones */}
          <Col xs={24} sm={12} md={8} lg={4} className="footer-links">
            <h3>{t('navigation')}</h3>
            <ul>
              <li>
                <RightOutlined />{' '}
                <a href="/history">{t('history')}</a>
              </li>
              <li>
                <RightOutlined />{' '}
                <a href="/#church-section">{t('churches')}</a>
              </li>
              <li>
                <RightOutlined />{' '}
                <a href="/news">{t('news')}</a>
              </li>
              <li>
                <RightOutlined />{' '}
                <a href="/prayers">{t('donate')}</a>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </Footer>
  );
};

export default CustomFooter;
