// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import es from './i18n/es.json';
import zh from './i18n/zh.json';

export const LOCALE_ES = "es";
export const LOCALE_ZH = "zh";

const detectLanguageFromDomain = () => {
  const domain = window.location.hostname;

  if (domain.endsWith('.es')) {
    return LOCALE_ES;  // Español para .es
  } else if (domain.endsWith('.com')) {
    return LOCALE_ZH;  // Inglés para .com
  } else {
    return LOCALE_ES;  // Idioma predeterminado, si no se reconoce el dominio
  }
};


i18n
  .use(initReactI18next)
  .init({
    resources: {
      es: { translation: es },
      zh: { translation: zh },
    },
    lng: detectLanguageFromDomain(), // Lenguaje de reserva
    interpolation: {
      escapeValue: false, // React ya protege de XSS
    },
  });

  // src/utils/localeUtils.js



export default i18n;
