import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, Layout } from 'antd';
import SEOHeader from '../components/SEOHeader';
import BackgroundImage from '../components/BackgroundImage';
import './OrganizationalStructure.css';
import { useLocation } from 'react-router-dom';

const OrganizationalStructure = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { t } = useTranslation();
  const standingCommittee = [
    { jobTitle: "主席", person: "陈正波 长老", imgUrl: "" },
    { jobTitle: "总干事", person: "卫 丰 牧师", imgUrl: "" },
    { jobTitle: "教育部长", person: "潘海平 牧师", imgUrl: "" },
    { jobTitle: "宣教部长", person: "庄鹤举 执事", imgUrl: "" },
    { jobTitle: "行政部长", person: "裘军雷 长老", imgUrl: "" }
  ];

  const council = [
    { person: "陈正波 长老" },
    { person: "裘军雷 长老" },
    { person: "卫 丰 牧师" },
    { person: "潘海平 牧师" },
    { person: "庄鹤举 执事" },
    { person: "庄首 执事" },
    { person: "陈 奇 传道" },
    { person: "杜海勇 执事" },
    { person: "黄震强 传道" },
    { person: "林荣恩 传道" },
    { person: "陈小芽 执事" },
    { person: "夏乐贤 弟兄" },
    { person: "林军永 执事" },
    { person: "陈正永 执事" },
    { person: "陈继远 弟兄" },
    { person: "陈联道 弟兄" }
  ];
  
  const generalOffice = [
    { jobTitle: "总干事", person: "卫 丰 牧师" },
    { jobTitle: "干事", person: "陈妙妙 姊妹" },
    { jobTitle: "干事", person: "林恩思 姊妹" }
  ];
  
  return (
    <div>
      <SEOHeader titleKey='icce_title' descriptionKey='icce_title' />
      <BackgroundImage title='organizational_structure' bgImgClass="structure-bg-image" />
      <Layout className="structure-layout">
        <h2 className='structure-title'>{t('standing_committee')}</h2>
        <div className="structure-container">
          <Row gutter={[16, 16]} justify="space-around">
            {standingCommittee.map((structure, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={4}>
                <Card className="structure-card">
                  <img
                    src={structure.imgUrl || '/user_icon.png'}
                    alt={structure.person}
                    className="structure-image"
                  />
                  <h2>{structure.jobTitle}</h2>
                  <h3>{structure.person}</h3>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <h2 className='structure-title'>{t('council')}</h2>
        <div className="structure-container no-image">
          <Row gutter={[16, 16]} justify="space-around">
            {council.map((structure, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={6}>
                <Card className="structure-card">
                  <h3>{structure.person}</h3>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <h2 className='structure-title'>{t('general_office')}</h2>
        <div className="structure-container no-image">
          <Row gutter={[16, 16]} justify="space-around">
            {generalOffice.map((structure, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={6}>
                <Card className="structure-card">
                  <h3>{structure.person}</h3>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Layout>
    </div>
  );
};

export default OrganizationalStructure;
