// src/axios.js
import axios from 'axios';
import { LOCALE_ES, LOCALE_ZH } from './i18n';
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const instance = axios.create({
  baseURL: BASE_URL, // URL base de tu API de Strapi
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`, // Token de autenticación
  },
});

export default instance;


export const getLocaleFilterAndRequest = (currentLang, requestId) => {
  let localeFilter = '';
  if (currentLang === 'es') {
    localeFilter = 'locale=es&';
    requestId = requestId + '-es';
  }
  return { localeFilter, requestId };
};

export const getLocaleFilter = (currentLang) => {
  return currentLang === 'es' ? 'locale=es&' : '';
};

export const fetchChurch = async (lang, churchId) => {
  const { localeFilter, requestId } = getLocaleFilterAndRequest(lang, churchId);

  try {
    const response = await instance.get(
      `/churches?${localeFilter}filters[uid][$eq]=${requestId}&populate=churchPic,informationPic&populate[1]=contactInfo.meetingTime`
    );
    return response.data.data[0]; // Retornamos el primer resultado
  } catch (error) {
    console.error(`Error fetching church data for locale ${lang}:`, error);
    throw error; // Lanzamos el error para que el componente lo maneje
  }
};


export const fetchChurchRequests = async (lang) => {
  const localeFilter = getLocaleFilter(lang);
  try {
    const response = await instance.get(
      `/church-requests?${localeFilter}`
    );
    return response.data.data;
  } catch (error) {
    console.error(`Error fetching church data for locale ${lang}:`, error);
    throw error; // Lanzamos el error para que el componente lo maneje
  }
};


export const fetchPrayerRequests = async (lang) => {
  const localeFilter = getLocaleFilter(lang);
  try {
    const response = await instance.get(
      `/prayer-requests?${localeFilter}`
    );
    return response.data.data;
  } catch (error) {
    console.error(`Error fetching church data for locale ${lang}:`, error);
    throw error; // Lanzamos el error para que el componente lo maneje
  }
};


export const fetchChurchRequestDetails = async (lang, churchRequestId) => {

  try {
    const response = await instance.get(
      `/church-requests/${churchRequestId}?populate=*`
    );
    const data = response.data.data.attributes;
    if (lang === LOCALE_ZH) lang = 'zh-Hans-CN';
    if (data?.locale === lang) {
      return data;
    }
    return data.localizations.data[0]?.attributes;
  } catch (error) {
    console.error(`Error fetching church data for locale ${lang}:`, error);
    throw error; // Lanzamos el error para que el componente lo maneje
  }
};

export const fetchDailyReading = async (lang) => {
  const localeFilter = getLocaleFilter(lang);
  const today = new Date();
  const formattedDate = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');

  try {
    const response = await instance.get(
      `/readings?${localeFilter}filters[publishDate][$eq]=${formattedDate}`
    );
    return response.data.data[0]; // Retornamos el primer resultado
  } catch (error) {
    console.error(`Error fetching church data for locale ${lang}:`, error);
    throw error; // Lanzamos el error para que el componente lo maneje
  }
};