import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, Layout } from 'antd';
import SEOHeader from '../components/SEOHeader';
import BackgroundImage from '../components/BackgroundImage';
import './OurPastors.css';
import { useLocation } from 'react-router-dom';

const OurPastors = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  const { t } = useTranslation();
  const pastorsArray = [
    { pastor: "Abel González Corcoba 传道", church: "马德里优塞拉堂（Madrid Usera）", imgUrl: "" },
    { pastor: "高杨 牧师", church: "富恩拉夫拉达堂（Madrid Fuenlabrada）", imgUrl: "" },
    { pastor: "朱家恩 传道", church: "富恩拉夫拉达堂（Madrid Fuenlabrada）", imgUrl: "" },
    { pastor: "陈微芬 教牧干事", church: "马德里北区堂（Madrid Alcobendas）", imgUrl: "" },
    { pastor: "黄震强 传道", church: "阿尔卡拉堂（Alcalá de Henares）", imgUrl: "" },
    { pastor: "徐云健 教牧干事", church: "奥维耶多堂（Oviedo）", imgUrl: "" },
    { pastor: "张温健 教牧干事", church: "潘普洛纳堂（Pamplona）", imgUrl: "" },
    { pastor: "黄志义 教牧干事", church: "圣地亚哥堂（Santiago）", imgUrl: "" },
    { pastor: "符巨甘 牧师", church: "巴塞罗那东区堂（Barcelona Este）", imgUrl: "" },
    { pastor: "陈奇 传道", church: "巴塞罗那东区堂（Barcelona Este）", imgUrl: "" },
    { pastor: "林荣恩 传道", church: "巴塞罗那南区堂（Barcelona Sur）", imgUrl: "" },
    { pastor: "陈华冠 传道", church: "巴塞罗那南区堂（Barcelona Sur）", imgUrl: "" },
    { pastor: "吴建斌 传道", church: "巴达洛纳堂（Badalona）", imgUrl: "" },
    { pastor: "李锡安 教牧干事", church: "曼雷萨堂（Manresa）", imgUrl: "" },
    { pastor: "李生 教牧干事", church: "帕尔马马略卡堂（Palma Mallorca）", imgUrl: "" },
    { pastor: "黄则快 教牧干事", church: "莱里达堂（Lleida）", imgUrl: "" },
    { pastor: "潘海平 牧师", church: "萨拉戈萨堂（Zaragoza）", imgUrl: "" },
    { pastor: "张诚 牧师", church: "圣科洛玛堂（Santa Coloma）", imgUrl: "" },
    { pastor: "季小彬 牧师", church: "阿利坎特堂（Alicante）", imgUrl: "" },
    { pastor: "黄国赞 牧师", church: "卡斯特利翁堂（Castellón）", imgUrl: "" },
    { pastor: "许滢仄 传道", church: "瓦伦西亚堂（Valencia）", imgUrl: "" }
  ];
  
  return (
    <div>
      <SEOHeader titleKey='icce_title' descriptionKey='icce_title' />
      <BackgroundImage title='our_pastors' bgImgClass="our-pastors-bg-image" />

      <Layout className="our-pastors-layout">
        <h2 className='pastor-title'>{t('full_time_pastor')}</h2>

        <div className="pastors-container">
        <Row gutter={[16, 16]} justify="space-around">
          {pastorsArray.map((pastor, index) => (
              <Col key={index} xs={24} md={8} >
                <Card className="pastor-card">
                  <img
                    src={pastor.imgUrl || '/user_icon.png'}
                    alt={pastor.pastor}
                    className="pastor-image"
                  />
                  <h2>{pastor.pastor}</h2>
                  <p>{pastor.church}</p>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Layout>
    </div>
  );
};

export default OurPastors;
