import React, { useEffect, useRef } from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import SEOHeader from '../components/SEOHeader';
import DailyDevotion from '../components/DailyDevotion';
import PrayerRequests from '../components/PrayerRequests';
import ChurchRequests from '../components/ChurchRequests';
import BackgroundImage from '../components/BackgroundImage';
import ChurchList from '../components/ChurchList';
import Donation from '../components/Donation';
import './Home.css';

const sedeId = process.env.REACT_APP_SEDE_ID;

const Home = () => {
  const location = useLocation();
  const donationRef = useRef();
  const churchRequestsRef = useRef();
  const scrollToComponent = (smooth = false) => {
    if (donationRef.current && location.hash == "#donation-section") {
      donationRef.current.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' });
      donationRef.current.focus();
    } else if (churchRequestsRef.current && location.hash == "#church-requests-section") {
      churchRequestsRef.current.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' });
      churchRequestsRef.current.focus();
    } else {
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => scrollToComponent(), [])
  useEffect(() => scrollToComponent(true), [location])
  return (
    <div>
      <SEOHeader titleKey='icce_title' descriptionKey='icce_title' />
      <Layout className="home-layout">
        <BackgroundImage title='slogan' bgImgClass="home-bg-image" />
        <DailyDevotion />
        <PrayerRequests/>
        <div id="church-requests-section" ref={churchRequestsRef}></div>
        <ChurchRequests/>
        <ChurchList />
        <div id="donation-section" ref={donationRef}></div>
        <Donation />
      </Layout>
    </div>
  );
};

export default Home;
