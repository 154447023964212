import React, { useState, useEffect } from 'react';
import { Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import './DailyDevotion.css';
import { fetchDailyReading } from '../axiosInstance';
import { LOCALE_ES } from '../i18n';

const DailyDevotion = () => {
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language ? i18n.language : LOCALE_ES;

  // Estado para la frase obtenida de la API
  const [dailyReading, setDailyReading] = useState(t('default_devotion'));
  const [loading, setLoading] = useState(true); // Para mostrar un spinner mientras carga


  const fetchDailyReadingData = async () => {
    try {
      const fetchedData = await fetchDailyReading(currentLang);
      if (!fetchedData && currentLang === LOCALE_ES) {
        fetchedData = await fetchDailyReading('zh');
      }
      if (fetchedData) {
        setDailyReading(fetchedData.attributes);
      } else {
        console.error('Error fetching devotion phrase');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching devotion phrase:', error);
      setLoading(false);
    }
  };
  // Llamada a la API cuando el componente se monta
  useEffect(() => {
    let isMounted = true; // Verificación de si el componente está montado
    fetchDailyReadingData();

    return () => {
      isMounted = false; // Evitar actualización de estado si el componente ya no está montado
    };
  }, []); // Dependencia vacía para que solo se ejecute una vez

  return (
    <div className="devotion-card-container">
      <Card title={t('daily_devotion')} className="devotion-card">
        {loading ? (
          <Spin size="large" />
        ) : (
          <a href={dailyReading.readingLink} target="_blank">《{dailyReading.readingTitle}》</a>
        )} {/* Mostrar spinner mientras carga */}
      </Card>
    </div>
  );
};

export default DailyDevotion;
