import React, { useState, useEffect, useRef } from 'react';
import { Carousel, Card, Layout, Button } from 'antd';
import RichTextRenderer from './RichTextRenderer'; // Asegúrate de que el componente RichTextRenderer esté importado
import './CustomCarousel.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CustomCarousel = ({ title, items, enableReadMore, detailUrl }) => {
  const [isContentOverflowing, setIsContentOverflowing] = useState({});
  const { t } = useTranslation();

  // Ref para cada card para medir el contenido
  const contentRefs = useRef([]);

  // Verifica si el contenido se desborda en cada tarjeta
  useEffect(() => {
    const checkOverflow = () => {
      const overflowStatus = {};
      contentRefs.current.forEach((ref, index) => {
        if (ref) {
          overflowStatus[index] = ref.scrollHeight > ref.clientHeight;
        }
      });
      setIsContentOverflowing(overflowStatus);
    };

    checkOverflow(); // Llama la función al montar
    window.addEventListener('resize', checkOverflow); // Vuelve a comprobar cuando la ventana cambia de tamaño

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [items]);

  return (

    <Layout className={`carousel-container ${enableReadMore ? 'enableReadMore' : ''}`}>
      <h2 className="carousel-title">{title}</h2>
      <Carousel
        arrows={true} // Mostrar flechas
        infinite={true} // Habilitar el bucle
        swipeToSlide={true} // Permitir arrastrar
        draggable={true} // Habilitar arrastre
        dotPosition="bottom" // Posición de los puntos
        autoplay={false}
        slidesToShow={items?.length>3? 3: items.length} // Mostrar 3 tarjetas a la vez
        slidesToScroll={1} // Mover una tarjeta por scroll
        responsive={[
          {
            breakpoint: 1024, // Si la pantalla es menor a 1024px
            settings: {
              slidesToShow: 2, // Mostrar 2 tarjetas
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768, // Si la pantalla es menor a 768px
            settings: {
              slidesToShow: 1, // Mostrar 1 tarjeta
              slidesToScroll: 1,
            },
          },
        ]}
      >
        {items.map((item, index) => (
          <div key={item.id}>
            <Card title={item.attributes.title} className="prayer-card">
              <div
                ref={(el) => (contentRefs.current[index] = el)}
                className="content-container"
              >
                <RichTextRenderer content={item.attributes.content} />
              </div>
              {isContentOverflowing[index] && (
                <Link
                  key={item.id}
                  className="read-more-link"
                  to={`/${detailUrl}/${item.id}`}
                  target="_blank"
                >
                  {t("read_more")}
                </Link>
              )}
            </Card>
          </div>
        ))}
      </Carousel>
    </Layout>
  );
};

export default CustomCarousel;
