import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Button, Layout, Row, Col, Form, Input, Modal } from 'antd';
import SEOHeader from '../components/SEOHeader';
import { useLocation } from 'react-router-dom';
import './ContactUs.css';
import { MailOutlined, EnvironmentOutlined } from '@ant-design/icons';
import instance from '../axiosInstance';

const ContactUs = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    message: '',
    isSuccess: false,
  });
  
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values) => {
    try {
      const response = await instance.post('/contact-messages', {
        name: values.name,
        email: values.email,
        message: values.message,
      });
      if (response.error) {
        showErrorModal();
      } else {
        setModalContent({
          title: t('contact-form-success-title'),
          message: t('contact-form-success-message'),
          isSuccess: true,
        });
        setIsModalVisible(true);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      showErrorModal();
    }
  };

  const showErrorModal = () => {
    setModalContent({
      title: t('form-error-title'),
      message: t('form-error-message'),
      isSuccess: false,
    });
    setIsModalVisible(true);
  }

  return (
    <>
      <Layout className="contact-us-layout">
        <SEOHeader titleKey='icce_title' descriptionKey='icce_title' />
        <h1>{t('contact_us')}</h1>

        <Row gutter={16}>
          <Col sm={24} md={12}>
            <iframe
              title="Google Maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3036.4903103178726!2d-3.702496699999999!3d40.44228369999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4228f7e6fcef3f%3A0xce1834ff05c6432f!2sC.%20de%20R%C3%ADos%20Rosas%2C%2011%2C%20Chamber%C3%AD%2C%2028003%20Madrid!5e0!3m2!1ses!2ses!4v1728658645882!5m2!1ses!2ses"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy='no-referrer-when-downgrade'
            ></iframe>
          </Col>
          <Col sm={24} md={12} >
            <div className='contact-section'>
              <p>
                <MailOutlined />{' '}
                <a href="mailto:Info@iccespain.com">Info@iccespain.com</a>
              </p>
              <p>
                <MailOutlined />{' '}
                <a href="mailto:Oficina@iccespain.com">Oficina@iccespain.com</a>
              </p>
              <p className='location-line'>
                <EnvironmentOutlined />{' '}
                <a
                  href="https://maps.app.goo.gl/Rjzqg7xjdW4fzdGE7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('office_address')}
                </a>
              </p>
            </div>
          </Col>
        </Row>

        <Row justify="center" className='form-contact-container'>
          <Col span={24} sm={24} md={12} lg={12}>
            <Card title={t('form_contact_us')} className='form-contact'>
              <Form form={form} layout="vertical" onFinish={onFinish}>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      rules={[{ required: true, message: t('please_input_name') }]}
                    >
                      <Input placeholder={t('input_name')} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="email"
                      rules={[{ required: true, type: 'email', message: t('please_input_valid_email') }]}
                    >
                      <Input placeholder={t('input_email')} />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name="message"
                  rules={[{ required: true, message: t('please_input_message') }]}
                >
                  <Input.TextArea placeholder={t('input_message')} rows={4} />
                </Form.Item>

                <Form.Item className='submit-button'>
                  <Button type="primary" htmlType="submit" >
                    {t('submit')}
                  </Button>
                </Form.Item>

              </Form>
            </Card>
          </Col>
        </Row>
        <Modal
          title={modalContent.title}
          open={isModalVisible}
          onOk={handleCloseModal}
          onCancel={handleCloseModal}
          footer={[
            <Button key="submit" type="primary" onClick={handleCloseModal}>
              OK
            </Button>,
          ]}
        >
          <p>{modalContent.message}</p>
        </Modal>
      </Layout>
    </>
  );
};

export default ContactUs;
