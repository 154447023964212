// RichTextRenderer.js
import React from 'react';

// Componente para renderizar contenido basado en el formato JSON
const RichTextRenderer = ({ content }) => {
  return (
    <>
      {content.map((block, index) => {
        switch (block.type) {
          case 'list':
            return (
              block.format === 'ordered' ? (
                <ol key={index}>
                  {block.children.map((item, itemIndex) => (
                    <li key={itemIndex}>
                      {item.children.map((child) => child.text).join('')}
                    </li>
                  ))}
                </ol>
              ) : (
                <ul key={index}>
                  {block.children.map((item, itemIndex) => (
                    <li key={itemIndex}>
                      {item.children.map((child) => child.text).join('')}
                    </li>
                  ))}
                </ul>
              )
            );

          case 'heading':
            const HeadingTag = `h${block.level}`;
            return (
              <HeadingTag key={index}>
                {block.children.map((child) => child.text).join('')}
              </HeadingTag>
            );

          case 'quote':
            return (
              <blockquote key={index}>
                {block.children.map((child) => child.text).join('')}
              </blockquote>
            );

          case 'paragraph':
            return (
              <p key={index}>
                {block.children.map((child) => child.text).join('')}
              </p>
            );

          default:
            return null; // Manejar tipos no reconocidos
        }
      })}
    </>
  );
};

export default RichTextRenderer;
