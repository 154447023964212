import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Button, Drawer, Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import './Navbar.css';
import churches from '../content/churches.json';

import { MenuOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
const { Header } = Layout;

const Navbar = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const location = useLocation();
  const [showChurchMenu, setShowChurchMenu] = useState(false);
  const timeoutRef = useRef(null);

  // Aquí detectamos si estamos en una iglesia
  const isChurchPage = location.pathname.startsWith('/church');

  const handleChurchMenuHover = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setShowChurchMenu(true);
  }, []);

  const handleChurchMenuLeave = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setShowChurchMenu(false);
    }, 200);
  }, []);

  const showSideMenu = () => {
    setOpenSideMenu(true);
  };

  const closeSideMenu = () => {
    setOpenSideMenu(false);
  };

  const closeChurchMenu = () => {
    setShowChurchMenu(false); // Cerrar el menú de las iglesias
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (openSideMenu) {
      closeSideMenu();
    }
    closeChurchMenu(); // Cerrar el menú de iglesias cuando cambia la ruta
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setVisible(false);
      } else {
        setVisible(true);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const aboutUsMenu = [
    { key: 'faith-declaration', label: <Link to="/faith-declaration">{t('faith_declaration')}</Link> },
    { key: 'history', label: <Link to="/history">{t('history')}</Link> },
    { key: 'history-timeline', label: <Link to="/history-timeline">{t('history_timeline')}</Link> },
    { key: 'our-pastors', label: <Link to="/our-pastors">{t('our_pastors')}</Link> },
    { key: 'organizational-structure', label: <Link to="/organizational-structure">{t('organizational_structure')}</Link> },
  ];

  const churchesMenu = churches.map(church => ({
    key: church.id,
    label: <Link to={`/church/${church.id}`}>{t(church.key)}</Link>,
  }));

  // Función para mapear la URL a la clave activa
  const getSelectedKey = () => {
    const path = location.pathname;

    if (path.startsWith('/church')) {
      return 'churches';
    } else if (path.startsWith('/history') || path.startsWith('/our-pastors') 
      || path.startsWith('/faith-declaration') || path.startsWith('/organizational-structure')) {
      return 'about_us';
    } else if (path.startsWith('/contact-us')) {
      return 'contact_us';
    } else if (location.hash?.includes('church-requests-section')) {
      return 'church_requests';
    } else if (location.hash?.includes('donation-section')) {
      return 'donate';
    }

    return ''; // Ninguna clave seleccionada por defecto
  };

  const items = [
    {
      key: 'about_us',
      label: t('about_us'),
      children: aboutUsMenu, // Submenú para "About Us"
    },
    {
      key: 'churches',
      label: isMobile ? t('churches') : (
        <span
          onMouseEnter={handleChurchMenuHover}
          onMouseLeave={handleChurchMenuLeave}
          className={`church-menu ${isChurchPage ? 'active' : ''}`}  // Añadir clase si estamos en una iglesia
        >
          {t('churches')}
        </span>
      ),
      children: isMobile ? churchesMenu : [], // Solo mostrar el submenú en móvil
    },
    { key: 'church_requests', label: <Link to="/#church-requests-section">{t('church_requests')}</Link> },
      { key: 'donate', label: <Link to="/#donation-section">{t('donate')}</Link> },
    { key: 'contact_us', label: <Link to="/contact-us">{t('contact_us')}</Link> },
  ];

  return (
    <Header className={`navbar ${visible ? 'navbar-visible' : 'navbar-hidden'} ${isMobile ? 'mobile-navbar' : ''}`}>
      <Link to="/" className="navbar-logo-title">
        <img src="/logo.png" alt="ICCE Logo" className="navbar-logo" />
        <h1 className="navbar-title">{isMobile ? t('short_title') : t('icce_title')}</h1>
      </Link>

      {isMobile ? (
        <>
          <Button type="text" onClick={showSideMenu}>
            <MenuOutlined />
          </Button>
          <Drawer
            placement="right"
            closable={false}
            onClose={closeSideMenu}
            open={openSideMenu}
            title={
              <div className="drawer-title">
                <Link to="/" className="navbar-logo-title">
                  <img src="/logo.png" alt="ICCE Logo" className="navbar-logo" />
                  <h1 className="navbar-title">{isMobile ? t('short_title') : t('icce_title')}</h1>
                </Link>
                <Button
                  type="text"
                  onClick={closeSideMenu}
                  icon={<MenuUnfoldOutlined className="close-button" />}
                />
              </div>
            }
          >
            <Menu mode="inline" items={items} selectedKeys={[getSelectedKey()]} />
            <div className="drawer-language-switcher">
              <LanguageSwitcher />
            </div>
          </Drawer>
        </>
      ) : (
        <div>
          <Menu mode="horizontal" items={items} className="navbar-menu" selectedKeys={[getSelectedKey()]} />
          <div
            className={`church-menu-grid ${showChurchMenu ? 'show' : ''}`}
            onMouseEnter={handleChurchMenuHover}
            onMouseLeave={handleChurchMenuLeave}
          >
            <div className="church-grid-container">
              {churches.map((church) => (
                church.url?
                <a className="church-grid-item" href={church.url} target="_blank">{t(church.key)} </a>:
                <Link
                  key={church.id}
                  className="church-grid-item"
                  to={`/church/${church.id}`}
                  onClick={closeChurchMenu} /* Cerrar el menú al hacer clic */
                >
                  {t(church.key)}
                </Link> 
              ))}
            </div>
          </div>
        </div>
      )}
      {!isMobile && (
        <div className="language-switcher">
          <LanguageSwitcher />
        </div>
      )}
    </Header>
  );
};

export default Navbar;
