// src/components/Donation.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { BankOutlined, CreditCardOutlined, UserOutlined } from '@ant-design/icons';
import './Donation.css';

const Donation = () => {
  const { t } = useTranslation();

  // Información de donación declarada dentro del componente
  const bankName = 'BBB Banco';
  const bankAccount = 'ES91 1234 5678 9012 3456';
/*   const bankUser = 'ICCE User';
 */
  return (
    <div className="donation-container">
      <Card title={t('donate')} className="donation-card">
        <div className="donation-details">
          <p><BankOutlined /> {t('bankName')}: {bankName}</p>
          <p><CreditCardOutlined /> {t('bankAccount')}: {bankAccount}</p>
{/*           <p><UserOutlined /> {t('bankUser')}: {bankUser}</p>
 */}        </div>
      </Card>
    </div>
  );
};

export default Donation;
